import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '../Card';
import ShortFacts from '../ShortFacts';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesNordr from './CardProject.nordr.module.scss';
import stylesFolkhem from './CardProject.folkhem.module.scss';

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

class CardProject extends React.PureComponent {
    render() {
        const { card, shortFacts, link, modifiers } = this.props;

        const theme = this.context;
        const styles = getStyles(theme);
        const isFolkhemTheme = theme === themes.FOLKHEM;

        const Tag = link ? 'a' : 'div';
        let attrs = {};
        if (link) {
            attrs.href = link.href;
        }

        const img =
            card.image && card.image.renditions
                ? card.image.renditions['standard900']
                : card.image;

        return (
            <Tag
                className={classNames(
                    styles["CardProject"],
                    modifiers
                        .filter((x) => x)
                        .map((x) => styles[`CardProject--${x}`])
                )}
                {...attrs}
            >
                <Card {...card} modifier={"WithinCardProject"} image={img} />
                <div className={styles["CardProject__ShortFacts"]}>
                    {!Object.keys(shortFacts).every(
                        (key) => shortFacts[key] === null
                    ) && <ShortFacts
                            showButton={!isFolkhemTheme}
                            modifier={'WithinCardProject'}
                            {...shortFacts}
                        />
                    }
                </div>
            </Tag>
        );
    }
}

CardProject.propTypes = {
    card: PropTypes.object,
    shortFacts: PropTypes.object,
    link: PropTypes.object,
    modifiers: PropTypes.arrayOf(PropTypes.string),
};

CardProject.defaultProps = {
    link: {
        href: '',
    },
    card: {
        image: {
            renditions: {},
        },
    },
    shortFacts: {},
    modifiers: [],
};

CardProject.contextType = ThemeContext;

export default CardProject;
